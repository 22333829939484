<template>
  <div id="addModify">
    <div class="headerDetail">
      <ds-header v-if="$route.query.type === 'view'" title="积分商品管理 / 查看自营商品"></ds-header>
      <ds-header v-else title="积分商品管理 / 修改自营商品"></ds-header>
      <a-button type="primary" @click="goback">取消</a-button>
    </div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      labelAlign="right"
      :labelCol="{ style: 'width: 120px' }"
      :rules="rules">
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>商品类型</div>
        <div class="content">
          <a-form-model-item label="商品类型：" prop="productType">
            <a-radio-group :disabled="true" v-model="form.productType" @change="handleType">
              <a-radio
                v-for="item in productTypeOptions"
                :key="item.commonsValue"
                :value="item.commonsValue">{{item.commonsValueName}}</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>基本信息</div>
        <div class="content">
          <a-form-model-item label="商品名称：" prop="productName" :span="24">
            <a-input :disabled="disabled" :maxLength="50" style="width: 200px;" placeholder="请输入商品名称" v-model="form.productName" />
          </a-form-model-item>
          <a-form-model-item label="商品标签：" prop="productLabel">
            <a-input :disabled="disabled" :maxLength="6" style="width: 200px;" v-model="form.productLabel" placeholder="请输入商品标签" />
          </a-form-model-item>
          <a-form-model-item label="封面图片" prop="coverPhoto">
            <a-upload
                :action="IMG_API + '/oss/files'"
                list-type="picture-card"
                :file-list="form.coverPhoto"
                @preview="handlePreviewCoverPhoto"
                @change="handleCoverPhotoChange"
                :disabled="disabled"
                :before-upload="beforeCoverPhotoUpload"
            >
              <div v-if="form.productImgList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <span>建议上传小于1MB的PNG、GIF或JPG图片，图片尺寸1：1</span>
            <a-modal
                :maskStyle="{padding: '10px'}"
                :visible="previewVisible" :footer="null" @cancel="handleCancelCoverPhotoPreview">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
          <a-form-model-item label="商品图片：" prop="productImgList">
            <a-upload
              :action="IMG_API + '/oss/files'"
              list-type="picture-card"
              :file-list="form.productImgList"
              @preview="handlePreview"
              @change="handleChange"
              :disabled="disabled"
              :before-upload="beforeUpload"
            >
              <div v-if="form.productImgList.length < 5">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <span>建议上传小于1MB的PNG、GIF或JPG图片，最多5张，图片尺寸1：1，默认第一张为头图</span>
            <a-modal
              :maskStyle="{padding: '10px'}"
              :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
          <a-form-model-item label="是否短信验证：" prop="needSmsValid">
            <a-radio-group
              :disabled="disabled"
              v-model="form.needSmsValid"
              :default-value="0">
              <a-radio :value="0">否</a-radio>
              <a-radio :value="1">是</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px"  v-if="form.productType === '20'">
        <div class="title"><span style="color:red">* </span>
          券活动ID
        </div>
        <div class="content">
          <a-form-model-item label="券活动ID" prop="activeId">
            <a-input ref="activeId"
                     v-model="form.activeId"
                     @blur="blurActiveId"
                     :disabled="!!cloneData.activeId"
                     placeholder="请填写对应的活动ID"
                     style="width: 200px" />
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px" v-else>
        <div class="title"><span style="color:red">* </span>
          配送类型
        </div>
        <div class="content">
          <a-form-model-item>
            <a-radio-group
              :disabled="disabled"
              v-model="form.distributionType"
              :default-value="0">
              <a-radio value="0">自提</a-radio>
              <a-radio value="1" disabled>配送</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="productSiteList">
            <div v-for="(item, index) in productSiteList" :key="item.id">
<!--              <a-input :maxLength="50"-->
<!--                       :disabled="disabled"-->
<!--                       v-model="item.pickAddressFirstLevel"-->
<!--                       @blur="blurPickAddressList"-->
<!--                       :placeholder="`请添加${form.distributionType === '0' ? '自提' : '配送'}地区`"-->
<!--                       style="width: 200px" />-->
              <a-select
                v-model="item.pickAddressFirstLevel"
                style="width: 200px;margin:0 15px"
                :disabled="disabled"
              >
                <a-select-option v-for="(arr, index) in plainOptions" :key="arr.value">
                  {{ arr.label }}
                </a-select-option>
              </a-select>
              <a-input :maxLength="50"
                       :disabled="disabled"
                       v-model="item.pickAddressSecondLevel"
                       @blur="blurPickAddressList"
                       :placeholder="`请添加${form.distributionType === '0' ? '自提' : '配送'}详细地址`"
                       style="width: 200px;margin:0 15px" />
              <a-button type="primary" v-if="!disabled" @click="addRegion">
                <a-icon type="plus" />
              </a-button>
              <a-button v-if="index !== 0" style="marginLeft:15px" type="danger" @click="delRegion(index)">
                <a-icon type="line" />
              </a-button>
            </div>
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>价格库存</div>
        <div class="content">
          <a-table
            :rowKey="(record, index) => index"
            bordered
            :pagination="false"
            :columns="columns"
            :data-source="[{}]"
          >
            <a-form-model-item slot="integralPrice" slot-scope="text, record" class="action" prop="productIntegral">
              <a-input :disabled="disabled" placeholder="请输入积分价(积分)" v-model="form.productIntegral" style="width: 200px" />
            </a-form-model-item>
            <a-form-model-item slot="originalCost" slot-scope="text, record" class="action" prop="productPrice">
              <a-input :disabled="disabled" placeholder="请输入原价(金额)" v-model="form.productPrice" style="width: 200px" />
            </a-form-model-item>
            <a-form-model-item slot="inventoryGross" slot-scope="text, record" class="action" prop="sumStock">
              <a-input
                :disabled="disabled"
                placeholder="请输入总库存数量"
                @blur="blurSumStock"
                v-model="form.sumStock" style="width: 200px" />
            </a-form-model-item>
            <a-form-model-item slot="closePrice" slot-scope="text, record" class="action" prop="settlePrice">
              <a-input :disabled="disabled" placeholder="请输入结算单价(金额)" v-model="form.settlePrice" style="width: 200px" />
            </a-form-model-item>
            <a-form-model-item slot="unitGross" slot-scope="text, record" class="action" prop="productUnit">
              <a-input placeholder="请输入计量单位数量" disabled v-model="form.productUnit" style="width: 200px" />
            </a-form-model-item>
          </a-table>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div class="title">商品详情</div>
        <div class="content">
          <div id="editer"></div>
        </div>
      </div>
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>归属项目</div>
        <div class="content">
          <a-form-model-item prop="productProjectList">
            <div>
              <a-checkbox :disabled="disabled" :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">全选</a-checkbox>
            </div>
            <a-checkbox-group :disabled="disabled" v-model="checkedList" :options="plainOptions" @change="onChange" />
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div class="title">关联楼层</div>
        <div class="content">
          <a-form-item label="选择楼层：">
            <div v-for="(item, index) in form.floorProductList" :key="item.id">
              <a-select
                :disabled="disabled"
                :allowClear="index === 0"
                style="width: 200px"
                placeholder="请选择"
                v-model="item.floorId"
                @change="floorsChange($event, index)"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option
                  v-for="option in floorsList"
                  :key="option.id"
                  :value="option.id"
                  :disabled="!option.floorState"
                >{{ option.floorName }}
                </a-select-option>
              </a-select>
              <a-button type="primary" v-if="!disabled" style="marginLeft:15px" @click="addFloor">
                <a-icon type="plus" />
              </a-button>
              <a-button v-if="index !== 0" type="danger" style="marginLeft:15px" @click="deleteFloor(index)">
                <a-icon type="line" />
              </a-button>
            </div>
          </a-form-item>
        </div>
      </div>
    </a-form-model>
    <div class="footer" v-if="!disabled">
      <a-button
        key="submit"
        style="margin-right: 30px"
        type="primary"
        @click="save"
      >保存
      </a-button>
    </div>
  </div>
</template>

<script>
import wangEditor from 'wangeditor'
import { IMG_API } from "@/config";
import {
  getProjectList,
  getProductList,
  getFloorList,
  addProduct,
  productDetail,
  updateProduct, getActive
} from '@/api/integralGoods'
import { projectName } from "../../../../api/pointsManagement"
import {mapState} from "vuex";


export default {
  computed: {
    ...mapState({
      plainOptions: (state) => {
        return state.common.projectList && state.common.projectList.length && state.common.projectList.map((item) => {
          return {
            value: item.id,
            label: item.project_name
          }
        })
      },
    }),
  },
  data () {
    return {
      // 当前活动羊毛券总库存
      currentCouponTotal: 0,
      editor: null,
      // 多选状态
      indeterminate: false,
      IMG_API: IMG_API,
      // 是否可预览
      previewVisible: false,
      // 是否可预览
      previewCoverPhotoVisible: false,
      // 预览图片
      previewImage: "",
      // 封面图片预览
      previewCoverPhoto: '',
      columns: [
        {
          title: "积分价",
          dataIndex: "integralPrice",
          key: "integralPrice",
          scopedSlots: { customRender: "integralPrice" }
        },
        {
          title: "原价",
          dataIndex: "originalCost",
          key: "originalCost",
          scopedSlots: { customRender: "originalCost" }
        },
        {
          title: "库存总量",
          dataIndex: "inventoryGross",
          key: "inventoryGross",
          scopedSlots: { customRender: "inventoryGross" }
        },
        {
          title: "结算单价(选填)",
          dataIndex: "closePrice",
          key: "closePrice",
          scopedSlots: { customRender: "closePrice" }
        },
        {
          title: "计量单位数量",
          dataIndex: "unitGross",
          key: "unitGross",
          align: 'center',
          scopedSlots: { customRender: "unitGross" }
        }
      ],
      productSiteList: [
        {
          pickAddressFirstLevel: "",
          pickAddressSecondLevel: ""
        }
      ],
      form: {
        productType: "", // 商品类型
        productName: "", // 商品名称
        coverPhoto: '', // 封面图片
        productLabel: "", // 商品标签
        productImgList: [], // 商品图片
        distributionType: 0, // 配送类型(0:自提，1:配送)
        productSiteList: [], // 自提地区
        productIntegral: "", // 商品积分价
        productPrice: "", // 商品原价
        sumStock: "", // 总库存(库存总量)
        settlePrice: "", // 结算单价
        productUnit: "1", // 计量单位数量
        productDetails: "", // 商品详情
        productProjectList: [], // 归属项目
        floorProductList: [''], // 关联楼层
        activeId: '', // 羊毛券
        needSmsValid: 0, // 是否短信验证
      },
      rules: {
        // 商品名称
        productName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          { max: 50, message: "商品名称长度不能超过50个字符", trigger: "blur" }
        ],
        // 商品标签
        productLabel: [
          { max: 6, message: "商品标签长度不能超过6个字符", trigger: "blur" }
        ],
        // 券活动ID
        activeId: [
          { required: true, message: "请填写对应的活动ID", trigger: ["blur", 'change'] }
        ],
        // 商品类型
        productType: [
          { required: true, message: "请选择商品类型", trigger: "change" }
        ],
        // 封面图片
        coverPhoto: [
          { required: true, message: '请上传封面图片', trigger: 'change' }
        ],
        // 自提地址
        productSiteList: [
          { required: true, message: "请输入自提地区和详细地址", trigger: "blur" }
        ],
        // 归属项目
        productProjectList: [
          { required: true, message: "请选择归属项目", trigger: "change" }
        ],
        // 商品积分价
        productIntegral: [
          { required: true, message: "请输入积分商品价", trigger: "blur" },
          { pattern: /^(0|[1-9][0-9]*)$/, message: '请输入0和正整数', trigger: 'blur' }
        ],
        // 商品原价
        productPrice: [
          { required: true, message: "请输入原价(金额)", trigger: "blur" },
          { pattern: /^\d+(\.\d{1,2})?$/, message: '请输入0、正整数或两位小数', trigger: 'blur' }
        ],
        // 总库存(库存总量)
        sumStock: [
          { required: true, message: "请输入总库存数量", trigger: "blur" },
          { pattern: /^(0|[1-9][0-9]*)$/, message: '请输入0和正整数', trigger: 'blur' }
        ],
        // 计量单位数量
        productUnit: [
          { required: true, message: "请输入计量单位数量", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: '请输入正整数', trigger: 'blur' }
        ],
        // 结算单价
        settlePrice: [
          { required: false, message: "请输入总库存数量", trigger: "blur" },
          { pattern: /^\d+(\.\d{1,2})?$/, message: '请输入0、正整数或两位小数', trigger: 'blur' }
        ],
        // 商品图片
        productImgList: [
          { required: true, message: "请上传商品图片", trigger: 'change' }
        ],
        // 是否短信验证
        needSmsValid: [
          { required: true, message: "请选择是否短信验证", trigger: "change" }
        ],
      },
      checkedList: [],
      checkAll: false,
      address: "",
      floorsList: [],
      //商品类型选项
      productTypeOptions: [],
      cloneData: null,
      disabled: false
    };
  },
  created () {
    this.loadGetProjectList()
    this.loadFloorList()
    this.loadProductList('product_type')
    if (this.$route.query?.id) {
      this.loadProductDetail(this.$route.query.id)
    }
    if (this.$route.query.type === "view") {
      this.disabled = true
    }
  },
  mounted () {
    const _this = this
    const editor = new wangEditor(`#editer`)
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.productDetails = newHtml
    }
    editor.config.menus = [
      'fontName',
      'fontSize',
      'foreColor',
      'backColor',
      'underline',
      'italic',
      'bold',
      'justify',
      'splitLine',
      'undo',
      'redo',
      'list',
      'table',
      'image',
      'video',
    ]
    editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgShowBase64 = false
    editor.config.showLinkImg = false
    editor.config.showLinkVideo = false
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
    editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = 'file'
    editor.config.uploadVideoName = 'file'
    editor.config.uploadVideoTimeout = 60000
    editor.config.uploadImgTimeout = 60000
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function(xhr) {
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        // result 即服务端返回的接口
        let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri +'" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do('insertHTML', videoHTML);
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        // insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function(xhr) {
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case 'success':
          _this.$message.success(s)
          break
        case 'info':
          _this.$message.info(s)
          break
        case 'warning':
          _this.$message.warning(s)
          break
        case 'error':
          _this.$message.error(s)
          break
        default:
          _this.$message.info(s)
          break
      }
    }
    editor.config.zIndex = 1;
    editor.config.placeholder = '请输入商品详情'
    // 创建编辑器
    editor.create()
    this.editor = editor
  },
  methods: {
    // 库存
    blurSumStock () {
      if (this.form.sumStock < this.form.marketNumber) {
        this.$message.warning('库存总量不能小于已销数量：' + this.form.marketNumber)
      }
    },
    floorsChange (evt, index) {
      const idx = this.form.floorProductList.findIndex((item, i) => item.floorId === evt && i !== index)
      if (idx !== -1) {
        // this.form.floorProductList[index] = {floorId: undefined}
        this.form.floorProductList.splice(index, 1, {floorId: undefined})
        this.$message.warning('请勿添加重复楼层')
      }

    },
    async blurActiveId () {
      if (!this.form.activeId) return
      const { data, code, msg } = await getActive(this.form.activeId)
      if (code === '200') {
        this.currentCouponTotal = data.sumStock;
      } else {
        this.$message.warning(msg)
      }
    },
    // 配送地址 blur 事件
    blurPickAddressList () {
      this.productSiteList.some(item => {
        if (item?.pickAddressFirstLevel
          && item?.pickAddressSecondLevel) {
          this.form.productSiteList = this.productSiteList
          this.$refs.ruleForm.clearValidate('productSiteList')
        } else {
          this.form.productSiteList = []
          this.$refs.ruleForm.validateField('productSiteList')
          return true
        }
      })
    },
    // 修改回显
    async loadProductDetail (id) {
      const { code, data, msg } = await productDetail(id)
      if (code === '200') {
        this.cloneData = JSON.parse(JSON.stringify(data))
        this.form = data
        if (data.productSiteList.length > 0) {
          this.productSiteList = data.productSiteList
        }
        this.form.productSiteList = []
        this.checkedList = data.productProjectList.map(item => item.projectId)
        this.indeterminate = !!this.checkedList.length && (this.checkedList.length < this.plainOptions.length)
        this.checkAll = this.checkedList.length === this.plainOptions.length
        this.editor.txt.html(this.form.productDetails)
        this.form.productImgList = data.productImgList.map(item => {
          return {
            uid: item.fileId,
            url: item.imgUrl,
            name: item.fileId
          }
        })
        if (data.floorProductList?.length === 0) {
          this.form.floorProductList = [{ floorId: undefined }]
        }
        // 如果类型为羊毛券，去查询当前羊毛券总库存
        if (this.form.productType === '20') {
          await this.blurActiveId();
        }
      } else {
        this.$message.error(msg)
      }
    },
    // 类型change
    handleType ({ target }) {
      this.$refs.ruleForm.clearValidate('activeId')
      if (target.value === '10') {
        document.querySelectorAll(".ant-form-explain").forEach(item => {
          if (item.innerHTML === '请填写对应的活动ID') {
            item.style.display = 'none';
          }
        })
      } else {
        document.querySelectorAll(".ant-form-explain").forEach(item => {
          if (item.innerHTML === '请填写对应的活动ID') {
            item.style.display = 'block';
          }
        })
      }
    },
    // 获取归属项目
    async loadGetProjectList () {
      const res = await projectName()
      this.plainOptions = res.data.items.map(item => {
        return ({
          value: item.id,
          label: item.project_name
        })
      })
      this.indeterminate = !!this.checkedList.length && (this.checkedList.length < this.plainOptions.length)
      this.checkAll = this.checkedList.length === this.plainOptions.length
    },
    // 获取楼层项目
    async loadFloorList () {
      const { data } = await getFloorList()
      this.floorsList = data
    },
    // 获取商品类型项目
    async loadProductList (id) {
      const res = await getProductList(id)
      this.productTypeOptions = res.data
    },
    // 返回
    goback () {
      this.$router.go(-1);
    },
    // 添加地区
    addRegion () {
      this.productSiteList.push({
        id: Math.random(),
        pickAddressFirstLevel: "",
        pickAddressSecondLevel: ""
      });
    },
    delRegion (index) {
      this.productSiteList.splice(index, 1)
      this.blurPickAddressList()
    },
    // 添加楼层
    addFloor () {
      if (this.form.floorProductList[this.form.floorProductList?.length - 1].floorId) {
        this.form.floorProductList.push({
          floorId: undefined
        })
      } else {
        this.$message.warning('请先选择楼层再添加')
      }
    },
    // 删除楼层
    deleteFloor (index) {
      this.form.floorProductList.splice(index, 1)
    },
    // 提交
    async save () {
      if (this.form.sumStock < this.form.marketNumber) {
        this.$message.warning('库存总量不能小于已销数量：' + this.form.marketNumber)
        return
      }
      if (this.form.productType === '20' && !this.form.activeId) {
        this.$refs.activeId.focus()
        this.$refs.activeId.blur()
      }
      let obj = JSON.parse(JSON.stringify(this.form))
      obj.productImgList = this.handleImg()
      obj.productSiteList = this.handleProductSiteList()
      this.form.productSiteList = this.handleProductSiteList().filter(item => !item.deleted)
      this.productSiteList.some(item => {
        if ((item.pickAddressSecondLevel === ''
          || item.pickAddressFirstLevel === '')
          && this.form.productType !== '20'
        ) {
          this.form.productSiteList = []
          return true
        }
      })
      if (this.form.productType === '20') {
        if (this.currentCouponTotal === 0) {
          this.$message.warn('券活动ID无效！')
          return;
        }
        if (this.form.sumStock > this.currentCouponTotal) {
          this.$message.warn('羊毛券库存不能大于创建时的库存！')
          return;
        }
      }
      const valid = await this.$refs.ruleForm.validate().catch(e => e);
      if (valid) {
        if (obj.productType === '20' && !obj.activeId) {
          return
        }
        if (obj.productType === '20') {
          obj.productSiteList = obj.productSiteList.filter(item => item.deleted)
        }
        obj.productProjectList = this.handleProductProjectList()
        // this.form.productProjectList = this.handleProductProjectList().filter(item => !item.deleted)
        obj.floorProductList = this.handleFloorProductList()
        const { code, msg } = await updateProduct(obj)
        if (code === '200') {
          this.goback()
          this.$message.success(msg)
        } else {
          this.$message.error(msg)
        }
      }
    },
    // 楼层数据管理
    handleFloorProductList () {
      const newList = this.form.floorProductList.map(item => item.floorId)
      let updateList = this.floorsList.filter(item => {
        return newList.includes(item.id)
      })
      updateList = JSON.parse(JSON.stringify(updateList)).map(item => {
        item.floorId = item.id
        delete item.id
        return item
      })
      // const delList = this.cloneData.floorProductList.map(item => {
      //   item.deleted = true
      //   return item
      // })
      return updateList
    },
    // 归属项目数据处理
    handleProductProjectList () {
      let updateList = this.plainOptions.filter(item => {
        return this.checkedList.includes(item.value)
      }).map(item => {
        return {
          projectId: item.value,
          projectName: item.label
        }
      })

      const del = this.cloneData.productProjectList.map(item => item.projectId)
      const update = updateList.map(item => item.projectId)
      updateList = updateList.filter(item => {
        return !del.includes(item.projectId)
      })
      const delList = this.cloneData.productProjectList.map(item => {
        if (!update.includes(item.projectId)) {
          item.deleted = true
        }
        return item
      })
      let res = [...updateList, ...delList]
      return res
    },
    // 配送地址数据处理
    handleProductSiteList () {
      const updateList = this.productSiteList.map(item => {
        return {
          pickAddressFirstLevel: item.pickAddressFirstLevel,
          pickAddressSecondLevel: item.pickAddressSecondLevel
        }
      })
      const delList = this.cloneData.productSiteList.map(item => {
        item.deleted = true
        return item
      })
      return [...updateList, ...delList]
    },
    // 图片处理
    handleImg () {
      const newImgs = []
      const oldImgs = []
      this.form.productImgList.forEach(item => {
        if (item.response) {
          newImgs.push(item.response.redirect_uri)
        } else {
          oldImgs.push(item.uid)
        }
      })
      const updateImgs = this.cloneData.productImgList.filter(item => {
        return !oldImgs.includes(item.fileId)
      })
      updateImgs.forEach(item => {
        item.deleted = true
      })
      newImgs.forEach(item => {
        updateImgs.push({
          imgUrl: item
        })
      })
      return updateImgs
    },
    // 上传图片前校验
    beforeUpload (file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg"
        || file.type === "image/png"
        || file.type === "image/jpg"
        || file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、gif或png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 1;
      if (!isLt2M) {
        this.$message.error("图片最大1M");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function(resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function() {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },
    /**
     * 封面图片的校验
     */
    beforeCoverPhotoUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg"
          || file.type === "image/png"
          || file.type === "image/jpg"
          || file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、gif或png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 1;
      if (!isLt2M) {
        this.$message.error("图片最大1M");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function(resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function() {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
          () => {
            return file;
          },
          () => {
            this.$message.error("图片不规范，请按要求上传");
            return Promise.reject();
          }
      );
      return isJpgOrPng && isLt2M && isSize;
    },
    // 关闭图片图层
    handleCancel () {
      this.previewVisible = false;
    },
    /**
     * 关闭封面浏览图层
     */
    handleCancelCoverPhotoPreview() {
      this.previewCoverPhotoVisible = false
    },
    // 预览图片
    handlePreview (file) {
      this.previewImage = file.url ? file.url : file.response.redirect_uri;
      this.previewCoverPhotoVisible = true;
    },
    /**
     * 预览封面图片
     */
    handlePreviewCoverPhoto(file) {
      this.previewCoverPhoto = file.url ? file.url : file.response.redirect_uri
      this.previewCoverPhotoVisible = true
    },

    // 上传图片
    handleChange ({ file, fileList }) {
      this.form.productImgList = fileList;
    },
    // 上传封面图片
    handleCoverPhotoChange({ file, fileList }) {
      this.form.coverPhoto = fileList;
    },
    // 全选项目
    onCheckAllChange (e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions.map(item => item.value) : [],
        checkAll: e.target.checked,
        indeterminate: false
      });
      this.form.productProjectList = this.plainOptions.filter(item => {
        return this.checkedList.includes(item.value)
      }).map(item => {
        return {
          projectId: item.value,
          projectName: item.label
        }
      })
    },
    // 多选
    onChange (checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
      if (checkedList.length > 0) {
        this.$refs.ruleForm.clearValidate('productProjectList')
        this.form.productProjectList = this.plainOptions.filter(item => {
          return this.checkedList.includes(item.value)
        }).map(item => {
          return {
            projectId: item.value,
            projectName: item.label
          }
        })
      } else {
        this.form.productProjectList = this.plainOptions.filter(item => {
          return this.checkedList.includes(item.value)
        }).map(item => {
          return {
            projectId: item.value,
            projectName: item.label
          }
        })
        this.$refs.ruleForm.validateField('productProjectList')
      }
    },
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
};
</script>

<style scoped lang="scss">
.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

::v-deep .ant-form-item {
  display: flex;
  margin-bottom: 0;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.content {
  /* margin-left:24px; */
  margin-top: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}

::v-deep .ant-checkbox-group {
  width: 100%;
  .ant-checkbox-group-item {
    width: 18%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}
::v-deep #editer {
  tr {
    height: 28.5px;
  }
}
::v-deep .ant-table-thead {
  th {
    &:before {
      content: '*';
      color: red;
    }
  }

  th:nth-child(4) {
    &:before {
      content: '';
    }
  }
}
::v-deep .ant-select-dropdown {
  z-index: 99999;
}
::v-deep .ant-modal-close-x {
  margin: -13px -13px 0 0;
}
</style>
