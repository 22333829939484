import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"
import store from '@/store'

const prefix = '/api/dscloud-member-mall'

// 获取商品列表
export const getIntegralGoodsList = (data, page, size) => {
  const submitData = {
    ...data,
    page,
    size
  };
  return fetchApi(URL.GET_INTEGRAL_GOODS_LIST, submitData, 'get', 'params', true);
}

// 获取项目列表
export const getProjectList = () => {
  return fetchApi(URL.GET_PROJECT_LIST)
}

// 获取楼层列表
export const getFloorList = () => {
  return fetchApi(URL.GET_ALL_FLOOR)
}

// 商品类型列表
export const getProductList = (id) => {
  return fetchApi(URL.GET_PRODUCT_LIST + id)
}

// 创建自营商品
export const addProduct = (data) => {
  return fetchApi(URL.ADD_PRODUCT, data, 'post', 'data')
}

// 修改自营商品
export const updateProduct = (data) => {
  return fetchApi(prefix + '/web/v2/product/update', data, 'put', 'data')
}

// 商品详情
export const productDetail = (id) => {
  return fetchApi(URL.DETAIL_PRODUCT + id)
}

// 羊毛券库存
export const getActive = (id) => {
  return fetchApi(URL.GET_ACTIVE_ID + id)
}

// 上下架
export const updateStatus = (id, type) => {
  return fetchApi(URL.UPDATE_STATUS + id + '/' + type, null, 'put')
}

// 冻结解冻
export const updateFrozen = (id, type) => {
  return fetchApi(prefix + `/web/v2/product/update/frozen/status/${id}/${type}`, null, 'put')
}

// 导出商品
export const exportProduct = (data) => {
  const currentRoleId = store.state.common.currentMenuRoleId
  const params = {
    ...data,
    roleId: currentRoleId
  }
  return http({
    url: URL.EXPORT_PRODUCT,
    responseType: 'blob',
    method: 'get',
    params: params
  })
}

/**
 * 获取所有商户信息
 */
export const getMerchants = () => {
  return fetchApi(prefix + '/web/v2/product/merchant/list')
}
